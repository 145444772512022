export type ChartDataSetType = {
  label: string;
  data: number[];
  backgroundColor: string;
};

export enum DateRangeProperties {
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum InputProperty {
  selectedApp = 'selectedApp',
}

export type StatisticsControlStateType = {
  selectedApp: {
    label: string;
    value: string;
  };
  startDate: string;
  endDate: string;
};

export type AnalyticsOverviewReturnType = {
  localTotalOrders: number;
  localTotalActivations: number;
  localCompletedOrders: number;
  localTotalZidFee: number;
};

export type AnalyticsOverviewFilterParamsType = {
  startDate: string;
  endDate: string;
};

export interface AnalyticsOverviewFilterParamsInterface {
  startDate?: string;
  endDate?: string;
}

export type ActivationChartData = {
  total_activations: string;
  total_deactivations: string;
};

export type ActivationsChartDataType = Record<string, ActivationChartData>;

export type OrderChartData = {
  total_orders: string;
  completed_orders: string;
};

export type OrdersChartDataType = Record<string, OrderChartData>;

export interface ChartsApiResponseInterface {
  activations: ActivationsChartDataType;
  orders: OrdersChartDataType;
}
