import BaseModel from '../../../api/models/BaseModel';
import { RateAndReviewDataType } from '../types/dashboard.type';

class LatestAppInstallsResponseModel extends BaseModel {
  public rates: RateAndReviewDataType[];

  constructor(arg: any) {
    super(arg);
    this.rates = arg.rates;
  }
}

export default LatestAppInstallsResponseModel;
