import httpClient from '../../../../api/httpClient';
import LatestRatingsAndReviewsResponseModel from '../../models/LatestRatingsAndReviewsResponseModel';
import ErrorModel from '../../../../api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/rating';

export const getLatestRatingsAndReviews = (applicationId: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}/${applicationId}`)
    .then((response) => new LatestRatingsAndReviewsResponseModel(response))
    .catch((error) => new ErrorModel(error));
};
