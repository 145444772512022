import Vue from 'vue';
import { defineComponent, watch, ref, reactive } from '@vue/composition-api';
import dayjs from 'dayjs';
import BaseText from '@/components/text/BaseText.vue';
import { ZidLoader } from '@zidsa/ui';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import TotalOrdersAnalyticsChart from '../statistics/total-orders-analytics-chart/TotalOrdersAnalyticsChart.vue';
import TotalActivationAnalyticsChart from './total-activation-analytics-chart/TotalActivationAnalyticsChart.vue';
import useTotalOrdersChart from '@/domains/home/helpers/hooks/shipping/useTotalOrdersChart';
import useTotalActivationsChart from '@/domains/home/helpers/hooks/shipping/useTotalActivationsChart';
import { getChartsData } from '@/domains/home/api/dashboard/shipping/charts';
import ChartsResponseModel from '@/domains/home/models/shipping/ChartsResponseModel';
import {
  OrderChartData,
  ChartDataSetType,
  ActivationChartData,
  ChartsApiResponseInterface,
} from '@/domains/home/types/shipping/dashboard.type';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  props: {
    statisticsControlState: {
      type: Object,
      required: true,
      default: {},
    },
    totalOrders: {
      type: Number,
      required: true,
      default: -1,
    },
    totalActivations: {
      type: Number,
      required: true,
      default: -1,
    },
    completedOrders: {
      type: Number,
      required: true,
      default: -1,
    },
    totalZidFee: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  components: {
    BaseText,
    BaseIcon,
    TotalOrdersAnalyticsChart,
    TotalActivationAnalyticsChart,
    ZidLoader,
  },
  setup(props) {
    const totalOrdersOfApp = ref(0);
    const totalActivationsOfApp = ref(0);
    const chartsResponse = ref<ChartsApiResponseInterface>({
      activations: {},
      orders: {},
    });
    const ACTIVATION_INDEX = 0;
    const DEACTIVATION_INDEX = 1;
    const ActivationsChartData = reactive<ChartDataSetType[]>([
      {
        label: 'Activations',
        backgroundColor: '#ACA1F8',
        data: [],
      },
      {
        label: 'Deactivations',
        backgroundColor: '#FCA19B',
        data: [],
      },
    ]);
    const TOTAL_ORDERS_INDEX = 0;
    const COMPLETED_ORDERS_INDEX = 1;
    const OrdersChartData = reactive<ChartDataSetType[]>([
      {
        label: 'Total Orders',
        backgroundColor: '#ACA1F8',
        data: [],
      },
      {
        label: 'Completed Orders',
        backgroundColor: '#10C88E',
        data: [],
      },
    ]);

    const { TotalOrdersChartData, TotalOrdersChartOptions, ordersChartDataLoaded } = useTotalOrdersChart();
    const { TotalActivationsChartData, TotalActivationsChartOptions, activationsChartDataLoaded } =
      useTotalActivationsChart();

    const isLoadingEnable = (entity: number) => {
      return entity === -1 ? true : false;
    };

    watch(
      () => props.statisticsControlState,
      async (newStatisticsState) => {
        const startDate = newStatisticsState.startDate;
        const endDate = newStatisticsState.endDate;

        if (startDate && endDate) {
          if (dayjs(startDate).isAfter(dayjs(endDate))) {
            Vue.$toast.error('Date range is not valid');
            return;
          }
          const selectedApplicationId = newStatisticsState.selectedApp.value;
          const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
          const formattedendDate = dayjs(endDate).format('YYYY-MM-DD');
          const params = {
            startDate: formattedStartDate,
            endDate: formattedendDate,
          };
          // get charts data fron API
          const response = await getChartsData(selectedApplicationId, params);
          if (response instanceof ErrorModel || !response.payload) {
            Vue.$toast.error('Failed to get charts data');
            return;
          }
          chartsResponse.value = response.payload;
          totalOrdersOfApp.value = Object.keys(chartsResponse.value.orders).reduce(
            (sum, key) => sum + parseInt(chartsResponse.value.orders[key].total_orders, 10),
            0,
          );
          totalActivationsOfApp.value = Object.keys(chartsResponse.value.activations).reduce(
            (sum, key) => sum + parseInt(chartsResponse.value.activations[key].total_activations, 10),
            0,
          );
          // get charts data fron API
        }
      },
      { deep: true },
    );

    watch(
      chartsResponse,
      () => {
        // activations
        const activations = chartsResponse.value.activations;
        const activationkeys = Object.keys(activations);
        ActivationsChartData[ACTIVATION_INDEX].data = [];
        ActivationsChartData[DEACTIVATION_INDEX].data = [];
        Object.entries(activations).forEach(([month, activationData]) => {
          const data = activationData as ActivationChartData;
          ActivationsChartData[ACTIVATION_INDEX].data.push(Number(data.total_activations));
          ActivationsChartData[DEACTIVATION_INDEX].data.push(Number(data.total_deactivations));
        });
        //update activation chart when data change or when filter change
        const activationChartNewValues = useTotalActivationsChart(activationkeys, ActivationsChartData);
        TotalActivationsChartData.data = activationChartNewValues.TotalActivationsChartData.data;
        TotalActivationsChartOptions.data = activationChartNewValues.TotalActivationsChartOptions.data;
        activationsChartDataLoaded.value = true;
        //update activation chart when data change or when filter change
        // orders
        const orders = chartsResponse.value.orders;
        const orderskeys = Object.keys(orders);
        OrdersChartData[TOTAL_ORDERS_INDEX].data = [];
        OrdersChartData[COMPLETED_ORDERS_INDEX].data = [];
        Object.entries(orders).forEach(([month, orderData]) => {
          const data = orderData as OrderChartData;
          OrdersChartData[TOTAL_ORDERS_INDEX].data.push(Number(data.total_orders));
          OrdersChartData[COMPLETED_ORDERS_INDEX].data.push(Number(data.completed_orders));
        });
        //update order chart when data change or when filter change
        const orderChartNewValues = useTotalOrdersChart(orderskeys, OrdersChartData);
        TotalOrdersChartData.data = orderChartNewValues.TotalOrdersChartData.data;
        TotalOrdersChartOptions.data = orderChartNewValues.TotalOrdersChartOptions.data;
        ordersChartDataLoaded.value = true;
        //update order chart when data change or when filter change
      },
      { deep: true },
    );

    return {
      isLoadingEnable,
      totalOrdersOfApp,
      totalActivationsOfApp,
      ordersChartDataLoaded,
      TotalOrdersChartData,
      TotalOrdersChartOptions,
      activationsChartDataLoaded,
      TotalActivationsChartData,
      TotalActivationsChartOptions,
    };
  },
});
