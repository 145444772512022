import BaseModel from '../../../api/models/BaseModel';
import { StoreDataType } from '../types/dashboard.type';

class LatestAppInstallsResponseModel extends BaseModel {
  public stores: StoreDataType[];

  constructor(arg: any) {
    super(arg);
    this.stores = arg.stores;
  }
}

export default LatestAppInstallsResponseModel;
