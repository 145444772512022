import Vue from 'vue';
import dayjs from 'dayjs';
import { defineComponent, reactive, watch } from '@vue/composition-api';
import BaseText from '../../../../../components/text/BaseText.vue';
import { ZidSwitch, ZidLoader } from '@zidsa/ui';
import BaseIcon from '../../../../../components/base-icon/BaseIcon.vue';
import ArrowUpIcon from '../../../../../assets/icons/domains/home/dashboard/ArrowUpIcon.vue';
import TotalSubsChart from '../statistics/total-subs-chart/TotalSubsChart.vue';
import useTotalSubscriptionChart from '../../../helpers/hooks/useTotalSubscriptionChart';
import InstallationsChart from './installations-chart/InstallationsChart.vue';
import useInstallationsChart from '../../../helpers/hooks/useInstallationsChart';
import { getMonthsList, isDateRangeValid } from '../../../helpers/dateManipulation';
import { getSubscriptionAnalytincs } from '../../../api/dashboard/subscriptionAnalytics';
import { getInstallationAnalytincs } from '../../../api/dashboard/installationsAnalytics';
import useFilterSubscriptionChartResponse from '../../../helpers/hooks/useFilterSubscriptionChartResponse';
import SubscriptionAnalyticsResponseModel from '../../../models/SubscriptionAnalyticsResponseModel';
import { calculateYAxisValues } from '../../../helpers/dynamicYAxisValues';

export default defineComponent({
  props: {
    statisticsControlState: {
      type: Object,
      required: true,
      default: {},
    },
    totalIncome: {
      type: Number,
      required: true,
      default: -1,
    },
    totalSubscription: {
      type: Number,
      required: true,
      default: -1,
    },
    totalRating: {
      type: Number,
      required: true,
      default: -1,
    },
  },
  components: {
    BaseText,
    BaseIcon,
    ArrowUpIcon,
    ZidSwitch,
    TotalSubsChart,
    InstallationsChart,
    ZidLoader,
  },
  setup(props) {
    let monthsList = reactive<string[]>([]);

    const {
      TotalSubsData,
      TotalSubsOptions,
      filterTotalSubsChartByPaid,
      subsChartDataLoaded,
      onChangeTotalSubsChartFilter,
    } = useTotalSubscriptionChart();
    const subscriptionChartResponse = reactive<Record<string, SubscriptionAnalyticsResponseModel>>({
      data: {
        payload: {
          paid_subscription: {},
          trial_subscription: {},
        },
      },
    });

    const { installationsData, installationsOptions, installationsDataLoaded } = useInstallationsChart('');
    watch(
      () => props.statisticsControlState,
      (newStatisticsState) => {
        const startDate = newStatisticsState.startDate;
        const endDate = newStatisticsState.endDate;
        const areDatesValid = isDateRangeValid(startDate, endDate);

        if (startDate && endDate) {
          if (!areDatesValid) {
            Vue.$toast.error('Date range is not valid');
            return;
          }
          monthsList = getMonthsList(startDate, endDate);
          const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
          const formattedendDate = dayjs(endDate).format('YYYY-MM-DD');
          const selectedApplicationId = newStatisticsState.selectedApp.value;
          // subscription chart
          getSubscriptionAnalytincs(selectedApplicationId, formattedStartDate, formattedendDate)
            .then((response) => {
              if ('payload' in response) {
                subscriptionChartResponse.data = response;
              }
            })
            .catch((error) => Vue.$toast.error('Failed to get total subscription analytics'));
          // subscription chart

          // installations chart
          getInstallationAnalytincs(selectedApplicationId, formattedStartDate, formattedendDate)
            .then((response) => {
              if ('payload' in response) {
                const { payload } = response;
                let appInstallationData = [payload.active_stores, payload.inactive_stores, payload.uninstall_stores];
                let appInstallationPercentages = [
                  payload.active_stores_percentage,
                  payload.inactive_stores_percentage,
                  payload.uninstall_stores_percentage,
                ];
                appInstallationData = appInstallationData.every((data) => data === 0)
                  ? [-1, -1, -1]
                  : appInstallationData;
                appInstallationPercentages = appInstallationPercentages.every((data) => data === 0)
                  ? [33, 33, 33]
                  : appInstallationPercentages;
                const installationsCharNewValues = useInstallationsChart(
                  newStatisticsState.selectedApp.value,
                  appInstallationData,
                  appInstallationPercentages,
                );
                installationsData.data = installationsCharNewValues.installationsData.data;
                installationsOptions.data = installationsCharNewValues.installationsOptions.data;
                installationsDataLoaded.value = true;
              }
            })
            .catch((error) => Vue.$toast.error('Failed to get installation analytics'));
          // installations chart
        }
        // subscription chart
      },
      { deep: true },
    );

    watch(
      [filterTotalSubsChartByPaid, subscriptionChartResponse],
      () => {
        //update subscription chart when data change or when filter change
        const { finalSubscriptionDatasetList } = useFilterSubscriptionChartResponse(
          subscriptionChartResponse.data,
          filterTotalSubsChartByPaid.value,
          monthsList,
        );
        const subscriptionChartNewValues = useTotalSubscriptionChart(
          monthsList,
          finalSubscriptionDatasetList,
          calculateYAxisValues(finalSubscriptionDatasetList),
        );
        TotalSubsData.data = subscriptionChartNewValues.TotalSubsData.data;
        TotalSubsOptions.data = subscriptionChartNewValues.TotalSubsOptions.data;
        subsChartDataLoaded.value = true;
        //update subscription chart when data change or when filter change
      },
      { deep: true },
    );

    return {
      TotalSubsData,
      TotalSubsOptions,
      filterTotalSubsChartByPaid,
      subsChartDataLoaded,
      installationsData,
      installationsOptions,
      installationsDataLoaded,
      onChangeTotalSubsChartFilter,
    };
  },
});
