import Vue from 'vue';
import { defineComponent, ref, reactive, onBeforeMount, watch } from '@vue/composition-api';
import { ZidIcon, ZidLoader, ZidTable, ZidTableRowGroup, ZidTableHeader, ZidTableRow, ZidTableCell } from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import { getLatestOrders } from './api/orders';
import ErrorModel from '@/api/models/ErrorModel';
import {
  OrderListType,
  OrderStatusLabelsEnum,
  OrderStatusValuesEnum,
  SettelmentStatusValuesEnum,
} from './types/latestOrders.type';

export default defineComponent({
  components: {
    BaseText,
    ZidIcon,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  props: {
    shippingAppId: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const loadingOrderList = ref(false);
    const latestOrders = reactive<OrderListType>({
      list: [],
    });

    const getLatestOrderFromApi = async (app_id: number) => {
      loadingOrderList.value = true;
      const response = await getLatestOrders(app_id);
      if (response instanceof ErrorModel || !response.orders) {
        Vue.$toast.error('Unable to get Orders');
        loadingOrderList.value = false;
        return;
      }
      latestOrders.list = response.orders;
      loadingOrderList.value = false;
    };

    watch(
      () => props.shippingAppId,
      async (newStatisticsState) => {
        const app_id = Number(newStatisticsState);
        await getLatestOrderFromApi(app_id);
      },
      { deep: true },
    );

    onBeforeMount(async () => {
      await getLatestOrderFromApi(Number(props.shippingAppId));
    });

    return {
      latestOrders,
      loadingOrderList,
      OrderStatusLabelsEnum,
      OrderStatusValuesEnum,
      SettelmentStatusValuesEnum,
    };
  },
});
