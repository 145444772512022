import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import {
  AnalyticsOverviewFilterParamsType,
  AnalyticsOverviewFilterParamsInterface,
} from '@/domains/home/types/shipping/dashboard.type';
import ChartsResponseModel from '@/domains/home/models/shipping/ChartsResponseModel';

const ENDPOINT_URL = '/market/shipping/analytics/chart';

export const getChartsData = (
  appId: string,
  filters: AnalyticsOverviewFilterParamsType,
): Promise<ChartsResponseModel | ErrorModel> => {
  const filterParams: AnalyticsOverviewFilterParamsInterface = {};

  if (filters.startDate) {
    filterParams.startDate = filters.startDate;
  }
  if (filters.endDate) {
    filterParams.endDate = filters.endDate;
  }

  return httpClient
    .get(`${ENDPOINT_URL}/${appId}`, {
      params: filterParams,
    })
    .then((response) => new ChartsResponseModel(response))
    .catch((error) => error);
};
