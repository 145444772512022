export enum OrderStatusLabelsEnum {
  All = 'All Statuses',
  InDelivery = 'Delivery In Progress',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Ready = 'Ready',
}

export enum OrderStatusValuesEnum {
  InDelivery = 3,
  Completed = 5,
  Canceled = 7,
  Ready = 17,
}

export enum SettelmentStatusValuesEnum {
  Settled = 'settled',
  NotSettled = 'not_settled',
}

export type SingleOrderType = {
  order_id: number;
  store_id: number;
  store_url: string;
  tracking_number: number;
  order_status: OrderStatusValuesEnum;
  create_date: string;
  delivery_date: string;
  customer: string;
  payment: number;
  total_order: number;
  zid_fees: number;
  settlement_status: SettelmentStatusValuesEnum;
};

export type OrderListType = {
  list: SingleOrderType[];
};
