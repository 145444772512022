import { PlanType, SubscriptionDateset } from '../types/dashboard.type';

export const filterPLans = (plansList: PlanType[], dataArray: SubscriptionDateset[]) => {
  if (plansList.length > 0) {
    for (let i = 0; i < dataArray.length; i++) {
      if (!plansList.some((plan) => plan.id === dataArray[i].plan_id)) {
        plansList.push({ id: dataArray[i].plan_id, name: dataArray[i].plan_name });
      }
    }
  } else {
    for (let i = 0; i < dataArray.length; i++) {
      plansList.push({ id: dataArray[i].plan_id, name: dataArray[i].plan_name });
    }
  }
  return plansList;
};

export const extractPlansList = (responseData: Record<string, SubscriptionDateset[]>) => {
  let localPlansList: PlanType[] = [];
  for (const monthlyData in responseData) {
    const dataArray: SubscriptionDateset[] = responseData[monthlyData];
    localPlansList = filterPLans(localPlansList, dataArray);
  }
  return localPlansList;
};
