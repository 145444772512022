import { defineComponent } from '@vue/composition-api';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import StarIcon from '@/assets/icons/domains/home/dashboard/StarIcon.vue';
import BaseText from '@/components/text/BaseText.vue';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ZidLoader } from '@zidsa/ui';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    BaseIcon,
    StarIcon,
    BaseText,
    ZidLoader,
  },
  setup() {
    const { name } = useGetters(['name']);

    return {
      name,
    };
  },
});
