import { getAnalytincsOverviewData } from '../../api/dashboard/analyticsOverview';
import { StatisticsControlStateType } from '../../types/dashboard.type';
import { isDateRangeValid } from '../dateManipulation';
import dayjs from 'dayjs';

const useAnalyticsOverview = async (statisticsControlState: StatisticsControlStateType) => {
  let localNextPayout = -1;
  let localTotalRating = -1;
  let localTotalSubscription = -1;
  let localTotalIncome = -1;
  const startDate = statisticsControlState.startDate;
  const endDate = statisticsControlState.endDate;
  const areDatesValid = isDateRangeValid(startDate, endDate);

  if (areDatesValid) {
    const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
    const formattedendDate = dayjs(endDate).format('YYYY-MM-DD');
    const selectedApplicationId = statisticsControlState.selectedApp.value;
    const response = await getAnalytincsOverviewData(selectedApplicationId, formattedStartDate, formattedendDate);
    localNextPayout = 'payload' in response ? response?.payload?.next_payout ?? 0 : 0;
    localTotalRating = 'payload' in response ? response?.payload?.total_rating_count ?? 0 : 0;
    localTotalSubscription = 'payload' in response ? response?.payload?.total_subscription ?? 0 : 0;
    localTotalIncome = 'payload' in response ? response?.payload?.partner_share + response?.payload?.zid_share ?? 0 : 0;
  } else {
    localNextPayout = 0;
    localTotalRating = 0;
    localTotalSubscription = 0;
    localTotalIncome = 0;
  }

  return {
    localNextPayout,
    localTotalRating,
    localTotalSubscription,
    localTotalIncome,
  };
};

export default useAnalyticsOverview;
