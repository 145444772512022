import Vue from 'vue';
import { defineComponent, reactive, ref, onBeforeMount, onMounted, watch } from '@vue/composition-api';
import store from '@/store';
import dayjs from 'dayjs';
import DashboardHeader from './dashboard-header/DashboardHeader.vue';
import LatestOrders from './latest-orders/LatestOrders.vue';
import StatisticsContainer from './statistics/StatisticsContainer.vue';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import { ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption, ZidDatepicker, ZidIcon } from '@zidsa/ui';
import { getShippingApplicationsList } from '@/api/top-level-apis/shipping/dashboard/shippingApplicationsList';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { InputProperty, DateRangeProperties } from '../../types/shipping/dashboard.type';
import useAnalyticsOverview from '../../helpers/hooks/shipping/useAnalyticsOverview';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    DashboardHeader,
    LatestOrders,
    StatisticsContainer,
    BaseIcon,
    ZidIcon,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    BaseText,
    ZidDatepicker,
  },
  setup() {
    const { setAccountCreationStepsState } = useActions(['setAccountCreationStepsState']);

    const dashboardAppsList: { label: string; value: string }[] = [];

    const totalOrders = ref(-1);
    const totalActivations = ref(-1);
    const completedOrders = ref(-1);
    const totalZidFee = ref(-1);

    const appsList = reactive(dashboardAppsList);
    const loadingAppsList = ref(false);
    const isAppsListDisabled = ref(false);

    const statisticsControlState = reactive({
      selectedApp: {
        label: '',
        value: '',
      },
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    });

    watch(
      statisticsControlState,
      async (newStatisticsControlState) => {
        if (dayjs(newStatisticsControlState.startDate).isAfter(dayjs(newStatisticsControlState.endDate))) {
          Vue.$toast.error('Date range is not valid');
          return;
        }
        totalOrders.value = -1;
        totalActivations.value = -1;
        completedOrders.value = -1;
        totalZidFee.value = -1;
        const { localTotalOrders, localTotalActivations, localCompletedOrders, localTotalZidFee } =
          await useAnalyticsOverview(newStatisticsControlState);
        totalOrders.value = localTotalOrders;
        totalActivations.value = localTotalActivations;
        completedOrders.value = localCompletedOrders;
        totalZidFee.value = localTotalZidFee;
      },
      { deep: true },
    );

    const onDataChange = (changedValue: Record<string, string>, property: 'selectedApp') => {
      statisticsControlState[property] = JSON.parse(JSON.stringify(changedValue));
    };

    const onDatepickerChange = (changedValue: Record<string, string>, dataProperty: DateRangeProperties) => {
      statisticsControlState[dataProperty] = changedValue.value;
    };

    onBeforeMount(async () => {
      loadingAppsList.value = true;
      const response = await getShippingApplicationsList();
      if (response instanceof ErrorModel || !response.payload) {
        isAppsListDisabled.value = true;
        loadingAppsList.value = false;
        return;
      }
      isAppsListDisabled.value = false;
      const apps = response.payload;
      apps.forEach((app: any) => {
        dashboardAppsList.push({
          label: app.name + ' - ' + app.name_ar,
          value: app.id?.toString() || '',
        });
      });
      statisticsControlState.selectedApp = dashboardAppsList.length ? dashboardAppsList[0] : { label: '', value: '' };
      loadingAppsList.value = false;

      const welcomeResponse = await welcomePartner();
      if (!(welcomeResponse instanceof ErrorModel) && welcomeResponse.payload) {
        await setAccountCreationStepsState(welcomeResponse.payload.partner_email);
      }
    });

    onMounted(() => {
      store.dispatch('setIsAppLoading', false);
    });

    return {
      InputProperty,
      DateRangeProperties,
      statisticsControlState,
      totalOrders,
      totalActivations,
      completedOrders,
      totalZidFee,
      appsList,
      loadingAppsList,
      isAppsListDisabled,
      onDataChange,
      onDatepickerChange,
    };
  },
});
