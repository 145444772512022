


















import { defineComponent } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
export default defineComponent({
  props: {
    storeName: {
      type: String,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    review: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseText,
  },
});
