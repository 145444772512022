






































import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import RatingAndReview from '../../../components/dashboard/RatingAndReview.vue';
import BaseText from '../../../../../components/text/BaseText.vue';
import { ZidIcon, ZidLoader } from '@zidsa/ui';
import { RateAndReviewDataType } from '../../../types/dashboard.type';
import { getLatestRatingsAndReviews } from '../../../api/dashboard/latestRatingsAndReviews';
import { getApplicationData } from '@/api/top-level-apis/application/application';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  props: {
    statisticsControlState: {
      type: Object,
      required: true,
      default: {},
    },
  },
  components: {
    BaseText,
    RatingAndReview,
    ZidIcon,
    ZidLoader,
  },
  setup(props) {
    const ratingAndReviewList = reactive<Record<string, RateAndReviewDataType[]>>({ list: [] });
    const loadingRatingAndReview = ref(false);
    const seeAllReviewsLink = ref('');
    const MAX_ALLOWED_RECORDS_TO_SHOW = 6;

    watch(
      () => props.statisticsControlState,
      async (newStatisticsState) => {
        loadingRatingAndReview.value = true;
        const selectedApplicationId = newStatisticsState.selectedApp.value;
        const response = await getLatestRatingsAndReviews(selectedApplicationId);
        if (response instanceof ErrorModel || !response.rates) {
          return;
        }
        const { rates } = response;
        if (Array.isArray(rates) && rates.length >= MAX_ALLOWED_RECORDS_TO_SHOW) {
          const latestRatings = rates.slice(0, MAX_ALLOWED_RECORDS_TO_SHOW);
          ratingAndReviewList.list = latestRatings;
        } else ratingAndReviewList.list = rates;

        if (isNaN(parseInt(selectedApplicationId))) {
          seeAllReviewsLink.value = '';
          loadingRatingAndReview.value = false;
          return;
        }
        const appResponse = await getApplicationData(parseInt(selectedApplicationId));
        if (!appResponse.payload) {
          seeAllReviewsLink.value = '';
          loadingRatingAndReview.value = false;
          return;
        }
        seeAllReviewsLink.value = appResponse.payload.app_url;
        loadingRatingAndReview.value = false;
      },
      { deep: true },
    );
    return {
      ratingAndReviewList,
      loadingRatingAndReview,
      seeAllReviewsLink,
    };
  },
});
