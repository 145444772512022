import httpClient from '../../../../api/httpClient';
import SubscriptionAnalyticsResponseModel from '../../models/SubscriptionAnalyticsResponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/analytics/subscription';

export const getSubscriptionAnalytincs = (applicationId: string, startDate: string, endDate: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}?appId=${applicationId}&startDate=${startDate}&endDate=${endDate}`)
    .then((response) => new SubscriptionAnalyticsResponseModel(response))
    .catch((error) => new ErrorModel(error));
};
