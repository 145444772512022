import BaseModel from '@/api/models/BaseModel';

class AnalyticsOverviewResponseModel extends BaseModel {
  public payload: {
    total_orders: number;
    completed_orders: number;
    total_zid_fee: number;
    total_activations: number;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default AnalyticsOverviewResponseModel;
