import dayjs from 'dayjs';
import { StatisticsControlStateType } from '../types/dashboard.type';

export const getMonthsList = (startDate: string, endDate: string): string[] => {
  const monthsList = [];
  let checkdate = dayjs(endDate);
  if (dayjs(dayjs(startDate).format('MMM')).isSame(dayjs(endDate).format('MMM'))) {
    monthsList.push(dayjs(startDate).format('MMM'));
  } else if (dayjs(startDate).format('MMM') === dayjs(endDate).subtract(1, 'month').format('MMM')) {
    monthsList.push(dayjs(checkdate).subtract(1, 'month').format('MMM'));
    monthsList.push(dayjs(checkdate).format('MMM'));
  } else {
    while (
      dayjs(checkdate).isAfter(dayjs(startDate)) ||
      dayjs(checkdate).format('MMM') === dayjs(startDate).format('MMM')
    ) {
      monthsList.unshift(dayjs(checkdate).format('MMM'));
      checkdate = dayjs(checkdate).subtract(1, 'month');
    }
  }
  return monthsList;
};

export const getSelectedDates = (newStatisticsState: StatisticsControlStateType) => {
  const startDate = JSON.parse(JSON.stringify(newStatisticsState.startDate));
  const endDate = JSON.parse(JSON.stringify(newStatisticsState.endDate));
  return {
    startDate,
    endDate,
  };
};

export const isDateRangeValid = (startDate: string, endDate: string) => {
  const datesInCorrectOrder = dayjs(startDate).isBefore(dayjs(endDate));
  const datesSameDay = dayjs(startDate).format('yyyy-MM-dd') === dayjs(endDate).format('yyyy-MM-dd');
  const endDateNotInFuture =
    dayjs(endDate).isBefore(dayjs().add(1, 'day')) &&
    dayjs(endDate).format('yyyy-MM-dd') !== dayjs().add(1, 'day').format('yyyy-MM-dd');
  const startDateNotLongerThanAYear =
    dayjs(startDate).isAfter(
      dayjs(endDate)
        .subtract(dayjs(endDate).date() - 1, 'day')
        .subtract(11, 'month'),
    ) || dayjs(dayjs(startDate).format('DD')).isSame(dayjs(endDate).format('DD'));

  return (datesInCorrectOrder || datesSameDay) && endDateNotInFuture && startDateNotLongerThanAYear;
};
