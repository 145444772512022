import { reactive, ref } from '@vue/composition-api';
import { SubscriptionFinalDataSetType } from '../../types/dashboard.type';

const useTotalSubscriptionChart = (
  monthsList: string[] = [],
  updatedDatasets: SubscriptionFinalDataSetType[] = [],
  yAxisData: Record<string, number> = {},
) => {
  const TotalSubsData = reactive({
    data: {
      labels: [''],
      datasets: [{}],
    },
  });
  const TotalSubsOptions = reactive({
    data: {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{}],
        yAxes: [{}],
      },
      legend: {},
      tooltips: {},
    },
  });
  const filterTotalSubsChartByPaid = ref(true);
  const subsChartDataLoaded = ref(false);

  const YAxisTicks = reactive({
    min: yAxisData.min ?? 0,
    stepSize: yAxisData.stepSize ?? 5,
    max: yAxisData.max ?? 50,
  });

  const onChangeTotalSubsChartFilter = (changedValue: Record<string, boolean>) => {
    filterTotalSubsChartByPaid.value = changedValue.isSwitched;
  };

  TotalSubsData.data = {
    labels: monthsList,
    datasets: updatedDatasets,
  };

  TotalSubsOptions.data = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            color: 'black',
            zeroLineColor: '#fff',
            zeroLineWidth: 0,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: YAxisTicks,
        },
      ],
    },
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 14,
        padding: 24,
        fontColor: '#8A94A6',
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${currentValue} ${currentValue === 1 ? 'subscription' : 'subscriptions'}`;
        },
      },
    },
  };

  subsChartDataLoaded.value = true;

  return {
    TotalSubsData,
    TotalSubsOptions,
    filterTotalSubsChartByPaid,
    subsChartDataLoaded,
    onChangeTotalSubsChartFilter,
  };
};

export default useTotalSubscriptionChart;
