import { reactive, ref } from '@vue/composition-api';

const useInstallationsChart = (
  applicationID: string,
  applicationData: number[] = [-1, -1, -1],
  appPercentages: number[] = [33, 33, 33],
) => {
  const installationsData = reactive({
    data: {
      labels: [''],
      datasets: [{}],
    },
  });

  const installationsOptions = reactive({
    data: {
      responsive: false,
      maintainAspectRatio: false,
      cutoutPercentage: 0,
      legend: {},
      tooltips: {},
    },
  });

  const installationsDataLoaded = ref(false);

  installationsData.data = {
    labels: ['Active Applications', 'Inactive Applications', 'Uninstalls'],
    datasets: [
      {
        label: applicationID,
        data: applicationData,
        backgroundColor: ['#70DEBB', '#FDE264', '#F97066'],
      },
    ],
  };

  installationsOptions.data = {
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 75,
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        fontSize: 14,
        padding: 24,
        fontColor: '#8A94A6',
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return ` ${currentValue !== -1 ? currentValue : 0} ${currentValue === 1 ? 'Application' : 'Applications'}`;
        },
      },
    },
  };

  return {
    installationsData,
    installationsOptions,
    installationsDataLoaded,
  };
};

export default useInstallationsChart;
