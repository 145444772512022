import httpClient from '../../../../api/httpClient';
import AnalyticsOverviewResponseModel from '../../models/AnalyticsOverviewResponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/analytics/overview';

export const getAnalytincsOverviewData = (applicationId: string, startDate: string, endDate: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}?appId=${applicationId}&startDate=${startDate}&endDate=${endDate}&duration=monthly`)
    .then((response) => new AnalyticsOverviewResponseModel(response))
    .catch((error) => new ErrorModel(error));
};
