import BaseModel from '../../../api/models/BaseModel';

class InstallationAnalyticsResponseModel extends BaseModel {
  public payload: {
    active_stores: number;
    active_stores_percentage: number;
    inactive_stores: number;
    inactive_stores_percentage: number;
    uninstall_stores: number;
    uninstall_stores_percentage: number;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default InstallationAnalyticsResponseModel;
