import BaseModel from '../../../api/models/BaseModel';
import { TrialSubscriptionType, PaidSubscriptionType } from '../types/dashboard.type';

class SubscriptionAnalyticsResponseModel extends BaseModel {
  public payload: {
    trial_subscription: TrialSubscriptionType;
    paid_subscription: PaidSubscriptionType;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default SubscriptionAnalyticsResponseModel;
