



















































































































































































import Vue from 'vue';
import store from '@/store';
import { defineComponent, ref, reactive, onBeforeMount } from '@vue/composition-api';
import { ZidCard, ZidCardHeader, ZidCardBody, ZidButton, ZidIcon, ZidModal } from '@zidsa/ui';
import BaseText from '../../../../components/text/BaseText.vue';
import Timeline from '../../components/timeline/Timeline.vue';
import { ApllicationTypesEnum } from '../../types/welcome.type';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { BOOK_MEETING_URL, JOIN_SLACK_URL, API_DOCUMENTATION } from '../../../../helpers/constantLinks';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions, useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidButton,
    BaseText,
    ZidIcon,
    Timeline,
    ZidModal,
  },
  setup() {
    window.scrollTo(0, 0);
    const showAppSelectionModal = ref(false);
    const documentationURL = ref(API_DOCUMENTATION);
    const bookMeetingURL = ref(BOOK_MEETING_URL);
    const slackURL = ref(JOIN_SLACK_URL);
    const progressPercentage = ref(20);
    const stepsLeft = ref(4);
    const stepsSuccess = reactive({
      confirmEmail: false,
      partnershipContract: false,
      developmentStore: false,
      createApp: false,
    });
    const accountStepsCompleted = ref(false);

    const { setAccountCreationStepsState, setAnAppHasBeenCreated } = useActions([
      'setAccountCreationStepsState',
      'setAnAppHasBeenCreated',
    ]);

    const toggleAppSelectionModal = () => (showAppSelectionModal.value = !showAppSelectionModal.value);

    onBeforeMount(async () => {
      const response = await welcomePartner();
      if (!(response instanceof ErrorModel) && response.payload) {
        let countOfStepsLeft = 0;
        // update store value to switch to dashboard if true --> same call in dashboard
        await setAnAppHasBeenCreated(response.payload.app_created);
        // allow and open other menu items if email is confirmed
        await setAccountCreationStepsState(response.payload.partner_email);
        accountStepsCompleted.value = response.payload.partner_email;
        if (response.payload.app_created && response.payload.partner_email) {
          router.replace({ name: RoutingRouteEnum.Dashboard }).catch(() => {
            /**/
          });
          return;
        }
        stepsSuccess.confirmEmail = response.payload.partner_email;
        stepsSuccess.partnershipContract = response.payload.partnership_contract;
        stepsSuccess.developmentStore = response.payload.development_store;
        stepsSuccess.createApp = response.payload.app_created;
        // update steps progress
        progressPercentage.value = response.payload.account_progress;
        if (!response.payload.partner_email) countOfStepsLeft++;
        if (!response.payload.partnership_contract) countOfStepsLeft++;
        if (!response.payload.development_store) countOfStepsLeft++;
        if (!response.payload.app_created) countOfStepsLeft++;
        stepsLeft.value = countOfStepsLeft;
        store.dispatch('setIsAppLoading', false);
      }
    });

    const onCreateAppBtnClicked = (appType: ApllicationTypesEnum) => {
      if (accountStepsCompleted.value) {
        if (appType == ApllicationTypesEnum.Public) {
          router.push({ name: RoutingRouteEnum.CreateApplication }).catch(() => {
            //
          });
        } else {
          router.push({ name: RoutingRouteEnum.CreateShippingApplication }).catch(() => {
            //
          });
        }
      } else {
        Vue.$toast.error(``, {
          message: 'Please complete your profile before creating an application',
          position: 'top',
        });
      }
    };

    return {
      stepsSuccess,
      stepsLeft,
      accountStepsCompleted,
      progressPercentage,
      documentationURL,
      bookMeetingURL,
      slackURL,
      ApllicationTypesEnum,
      showAppSelectionModal,
      onCreateAppBtnClicked,
      toggleAppSelectionModal,
    };
  },
});
