import Vue from 'vue';
import { defineComponent, reactive, ref, onBeforeMount, watch, onMounted } from '@vue/composition-api';
import store from '@/store';
import dayjs from 'dayjs';
import DashboardHeader from './dashboard-header/DashboardHeader.vue';
import StatisticsContainer from './statistics/StatisticsContainer.vue';
import LatestInstalls from './latest-installs/LatestInstalls.vue';
import RatingsAndReviews from './ratings-reviews/RatingsAndReviews.vue';
import { ZidSelect, ZidSelectHeader, ZidSelectBody, ZidSelectOption, ZidDatepicker } from '@zidsa/ui';
import BaseText from '../../../../components/text/BaseText.vue';
import { getApplicationsList } from '@/api/top-level-apis/dashboard/applicationsList';
import { ApplicationSelection, DateRangeProperties } from '../../types/dashboard.type';
import { welcomePartner } from '@/api/top-level-apis/welcome-partner/welcomePartner';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import useAnalyticsOverview from '../../helpers/hooks/useAnalyticsOverview';
import ApplicationModel, { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';

const { useActions } = createNamespacedHelpers('authentication');

export default defineComponent({
  components: {
    DashboardHeader,
    StatisticsContainer,
    LatestInstalls,
    RatingsAndReviews,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    BaseText,
    ZidDatepicker,
  },
  setup() {
    const { setAccountCreationStepsState } = useActions(['setAccountCreationStepsState']);

    const dashboardAppsList: ApplicationSelection[] = [];

    const nextPayout = ref(0);
    const totalRating = ref(0);
    const totalSubscription = ref(0);
    const totalIncome = ref(0);

    const appsList = reactive(dashboardAppsList);
    const loadingAppsList = ref(false);
    const isAppsListDisabled = ref(false);

    const statisticsControlState = reactive({
      selectedApp: {
        label: '',
        value: '',
      },
      startDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
    });

    onBeforeMount(async () => {
      loadingAppsList.value = true;
      const response = await getApplicationsList();
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to load Applications');
        isAppsListDisabled.value = true;
        loadingAppsList.value = false;
        return;
      }
      isAppsListDisabled.value = false;
      const apps = response.payload;
      apps.map((app: ApplicationModel) => {
        if (app.type !== ApplicationTypeProperty.Shipping) {
          dashboardAppsList.push({
            label: app.name,
            value: app.id?.toString() || '',
          });
        }
      });
      statisticsControlState.selectedApp = dashboardAppsList.length ? dashboardAppsList[0] : { label: '', value: '' };
      loadingAppsList.value = false;

      const welcomeResponse = await welcomePartner();
      if ('payload' in welcomeResponse) {
        await setAccountCreationStepsState(welcomeResponse.payload.partner_email);
      }
    });

    onMounted(() => {
      store.dispatch('setIsAppLoading', false);
    });

    watch(
      statisticsControlState,
      async (newStatisticsControlState) => {
        nextPayout.value = -1;
        totalRating.value = -1;
        totalSubscription.value = -1;
        totalIncome.value = -1;
        const { localNextPayout, localTotalRating, localTotalSubscription, localTotalIncome } =
          await useAnalyticsOverview(newStatisticsControlState);
        nextPayout.value = localNextPayout;
        totalRating.value = localTotalRating;
        totalSubscription.value = localTotalSubscription;
        totalIncome.value = localTotalIncome;
      },
      { deep: true },
    );

    const onDataChange = (changedValue: Record<string, string>, property: 'selectedApp') => {
      statisticsControlState[property] = JSON.parse(JSON.stringify(changedValue));
    };

    const onDatepickerChange = (changedValue: Record<string, string>, dataProperty: DateRangeProperties) => {
      statisticsControlState[dataProperty] = changedValue.value;
    };

    return {
      statisticsControlState,
      nextPayout,
      totalRating,
      totalSubscription,
      totalIncome,
      appsList,
      loadingAppsList,
      isAppsListDisabled,
      onDataChange,
      onDatepickerChange,
    };
  },
});
