import httpClient from '../../../api/httpClient';
import ResendConfirmationEmailResponse from '../models/ResendConfirmationEmailResponse';

const ENDPOINT_URL = '/market/mail/resend';

export const reSendPartnerConfirmationEmail = (email: Record<string, string>) => {
  return httpClient
    .post(ENDPOINT_URL, email)
    .then((response) => new ResendConfirmationEmailResponse(response))
    .catch((error) => error);
};
