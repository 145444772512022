import { reactive, ref } from '@vue/composition-api';
import { ChartDataSetType } from '@/domains/home/types/shipping/dashboard.type';

const useTotalActivationsChart = (monthsList: string[] = [], updatedDatasets: ChartDataSetType[] = []) => {
  const TotalActivationsChartData = reactive({
    data: {
      labels: [''],
      datasets: [{}],
    },
  });
  const TotalActivationsChartOptions = reactive({
    data: {
      responsive: false,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{}],
        yAxes: [{}],
      },
      legend: {},
      tooltips: {},
    },
  });
  const activationsChartDataLoaded = ref(false);

  TotalActivationsChartData.data = {
    labels: monthsList,
    datasets: updatedDatasets,
  };

  TotalActivationsChartOptions.data = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            color: 'black',
            zeroLineColor: '#fff',
            zeroLineWidth: 0,
          },
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
    legend: {
      position: 'bottom',
      labels: {
        fontSize: 14,
        padding: 24,
        fontColor: '#8A94A6',
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${currentValue} ${currentValue === 1 ? 'Activation' : 'Activations'}`;
        },
      },
    },
  };

  activationsChartDataLoaded.value = true;

  return {
    TotalActivationsChartData,
    TotalActivationsChartOptions,
    activationsChartDataLoaded,
  };
};

export default useTotalActivationsChart;
