



















































import { defineComponent, reactive, ref, watch } from '@vue/composition-api';
import BaseText from '../../../../../components/text/BaseText.vue';
import { getLatestApplicationInsalls } from '../../../api/dashboard/latestApplicationInstalls';
import { StoreDataType } from '../../../types/dashboard.type';
import { ZidLoader, ZidTable, ZidTableRowGroup, ZidTableHeader, ZidTableRow, ZidTableCell } from '@zidsa/ui';
export default defineComponent({
  props: {
    statisticsControlState: {
      type: Object,
      required: true,
      default: {},
    },
  },
  components: {
    BaseText,
    ZidLoader,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
  },
  setup(props) {
    const latestAppInstalls = reactive<Record<string, StoreDataType[]>>({ list: [] });
    const loadingLatestAppInstalls = ref(false);
    const MAX_ALLOWED_RECORDS_TO_SHOW = 6;

    watch(
      () => props.statisticsControlState,
      async (newStatisticsState) => {
        loadingLatestAppInstalls.value = true;
        const selectedApplicationId = newStatisticsState.selectedApp.value;
        const response = await getLatestApplicationInsalls(selectedApplicationId);
        if ('stores' in response && response.stores !== undefined) {
          const { stores } = response;
          if (stores.length >= MAX_ALLOWED_RECORDS_TO_SHOW) {
            const latestStores = stores.slice(0, MAX_ALLOWED_RECORDS_TO_SHOW);
            latestAppInstalls.list = latestStores;
          } else latestAppInstalls.list = stores;
          loadingLatestAppInstalls.value = false;
        } else latestAppInstalls.list = [];

        loadingLatestAppInstalls.value = false;
      },
      { deep: true },
    );

    return {
      latestAppInstalls,
      loadingLatestAppInstalls,
    };
  },
});
