import httpClient from '../../../../api/httpClient';
import InstallationAnalyticsResponseModel from '../../models/InstallationAnalyticsResponseModel';
import ErrorModel from '@/api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/analytics/installation';

export const getInstallationAnalytincs = (applicationId: string, startDate: string, endDate: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}?appId=${applicationId}&startDate=${startDate}&endDate=${endDate}`)
    .then((response) => new InstallationAnalyticsResponseModel(response))
    .catch((error) => new ErrorModel(error));
};
