import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import OrdersResponseModel from '../models/OrdersResponseModel';

const ENDPOINT_URL = '/market/shipping/orders/latest';

export const getLatestOrders = (app_id: number): Promise<OrdersResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${app_id}`)
    .then((response) => new OrdersResponseModel(response))
    .catch((error) => error);
};
