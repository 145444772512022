import BaseModel from '../../../api/models/BaseModel';

class AnalyticsOverviewResponseModel extends BaseModel {
  public payload: {
    partner_share: number;
    partner_share_previous_month: number;
    partner_share_percentage_change: number;
    zid_share: number;
    zid_share_previous_month: number;
    zid_share_percentage_change: number;
    total_subscription: number;
    total_subscription_previous_month: number;
    total_subscription_percentage_change: number;
    next_payout: number;
    next_payout_previous_month: number;
    next_payout_percentage_change: number;
    average_rating: number;
    total_rating_count: number;
  };

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default AnalyticsOverviewResponseModel;
