import BaseModel from '@/api/models/BaseModel';
import { SingleOrderType } from '../types/latestOrders.type';

class OrdersResponseModel extends BaseModel {
  public orders: SingleOrderType[];

  constructor(arg: any) {
    super(arg);
    this.orders = arg.orders;
  }
}

export default OrdersResponseModel;
