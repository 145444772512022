import httpClient from '../../../../api/httpClient';
import LatestAppInstallsResponseModel from '../../models/LatestAppInstallsResponseModel';
import ErrorModel from '../../../../api/models/ErrorModel';

const ENDPOINT_URL = '/market/dashboard/app/latest';

export const getLatestApplicationInsalls = (applicationId: string) => {
  return httpClient
    .get(`${ENDPOINT_URL}?appId=${applicationId}`)
    .then((response) => new LatestAppInstallsResponseModel(response))
    .catch((error) => new ErrorModel(error));
};
