import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import { AnalyticsOverviewFilterParamsInterface } from '../../../types/shipping/dashboard.type';
import AnalyticsOverviewResponseModel from '../../../models/shipping/AnalyticsOverviewResponseModel';

const ENDPOINT_URL = 'market/shipping/analytics/counts';

export const getAnalytincsOverviewData = (
  applicationId: string,
  startDate: string,
  endDate: string,
): Promise<AnalyticsOverviewResponseModel | ErrorModel> => {
  const filterParams: AnalyticsOverviewFilterParamsInterface = {};

  if (startDate) {
    filterParams.startDate = startDate;
  }
  if (endDate) {
    filterParams.endDate = endDate;
  }
  return httpClient
    .get(`${ENDPOINT_URL}/${applicationId}`, {
      params: filterParams,
    })
    .then((response) => new AnalyticsOverviewResponseModel(response))
    .catch((error) => error);
};
