import { ref, reactive } from '@vue/composition-api';
import dayjs from 'dayjs';
import SubscriptionAnalyticsResponseModel from '../../models/SubscriptionAnalyticsResponseModel';
import {
  TrialSubscriptionType,
  PaidSubscriptionType,
  SubscriptionFinalDataSetType,
  SubscriptionDateset,
  PlanType,
} from '../../types/dashboard.type';
import { extractPlansList } from '../filterPlans';

// static colors for subscription chart with max 4 plans
const plansColorsList = ['#377DFF', '#FFA340', '#38CB89', '#DB637B'];

const useFilterSubscriptionChartResponse = (
  response: SubscriptionAnalyticsResponseModel,
  isPaidPlanSelected: boolean,
  monthsList: string[],
) => {
  const trialSubscriptionResponseData = reactive<TrialSubscriptionType>(response.payload.trial_subscription);
  const paidSubscriptionResponseData = reactive<PaidSubscriptionType>(response.payload.paid_subscription);
  let finalSubscriptionDatasetList = reactive<SubscriptionFinalDataSetType[]>([]);
  let plansList = reactive<PlanType[]>([]);

  if (isPaidPlanSelected) {
    plansList = [];
    plansList = extractPlansList(paidSubscriptionResponseData);
  } else {
    plansList = [];
    plansList = extractPlansList(trialSubscriptionResponseData);
  }

  if (isPaidPlanSelected)
    finalSubscriptionDatasetList = exctractSubscriptionChartDatasets(
      plansList,
      paidSubscriptionResponseData,
      monthsList,
    );
  else
    finalSubscriptionDatasetList = exctractSubscriptionChartDatasets(
      plansList,
      trialSubscriptionResponseData,
      monthsList,
    );

  return { finalSubscriptionDatasetList };
};

const exctractSubscriptionChartDatasets = (
  plansList: PlanType[],
  responseData: Record<string, SubscriptionDateset[]>,
  monthsList: string[],
) => {
  const modifiedResponseData: {
    responseMonth: string;
    responseData: SubscriptionDateset[];
  }[] = [];
  for (const [dataDate, value] of Object.entries(responseData)) {
    modifiedResponseData.push({
      responseMonth: dayjs(dataDate).format('MMM'),
      responseData: value,
    });
  }
  const localDataSet: SubscriptionFinalDataSetType[] = [];
  plansList.forEach((plan, index) => {
    const monthlyPlanData: number[] = [];
    monthsList.forEach((month) => {
      if (modifiedResponseData.some((response) => response.responseMonth === month)) {
        const monthData = modifiedResponseData.find((response) => response.responseMonth === month)?.responseData;
        monthData?.map((monthDataset) => {
          if (plan.id === monthDataset.plan_id) monthlyPlanData.push(monthDataset.store_count);
        });
      } else {
        monthlyPlanData.push(0);
      }
    });

    localDataSet.push({
      label: plan.name,
      data: monthlyPlanData,
      backgroundColor: plansColorsList[index],
    });
  });
  return localDataSet;
};

export default useFilterSubscriptionChartResponse;
