import BaseModel from '@/api/models/BaseModel';
import { ChartsApiResponseInterface } from '../../types/shipping/dashboard.type';

class ChartsResponseModel extends BaseModel {
  public payload: ChartsApiResponseInterface;

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default ChartsResponseModel;
