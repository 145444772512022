import { SubscriptionFinalDataSetType } from '../types/dashboard.type';

const LOW_Y_AXIS_MAX = 50;
const HIGH_Y_AXIS_MAX = 100;

export const calculateYAxisValues = (updatedDatasets: SubscriptionFinalDataSetType[]) => {
  const YAxisMin = 0;
  let YAxisStepSize = 5;
  let YAxisMax = 50;
  const datasetValues = updatedDatasets.map((set) => set.data).flat();

  if (Math.max(...datasetValues) < LOW_Y_AXIS_MAX) {
    if (!datasetValues.length) YAxisMax = LOW_Y_AXIS_MAX;
    else YAxisMax = Math.ceil(getTheClosestTenthValue(datasetValues) / LOW_Y_AXIS_MAX) * LOW_Y_AXIS_MAX;
  } else {
    YAxisMax = Math.ceil(getTheClosestTenthValue(datasetValues) / HIGH_Y_AXIS_MAX) * HIGH_Y_AXIS_MAX;
  }

  if (YAxisMax <= LOW_Y_AXIS_MAX) {
    YAxisStepSize = 5;
  } else if (YAxisMax <= HIGH_Y_AXIS_MAX) {
    YAxisStepSize = 10;
  } else if (YAxisMax <= HIGH_Y_AXIS_MAX * 2) {
    YAxisStepSize = 20;
  } else {
    YAxisStepSize = 50;
  }

  return {
    min: YAxisMin,
    stepSize: YAxisStepSize,
    max: YAxisMax,
  };
};

const getTheClosestTenthValue = (datasetValues: number[]) => {
  return Math.ceil(Math.max(...datasetValues) / 10) * 10;
};
