import Vue from 'vue';
import { defineComponent, ref, computed } from '@vue/composition-api';
import BaseText from '../../../../components/text/BaseText.vue';
import { ZidIcon, ZidButton, ZidModal, ZidInput } from '@zidsa/ui';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { reSendPartnerConfirmationEmail } from '../../api/re-sendMail';
import router from '@/router';
import { RoutingRouteEnum } from '@/router/routes.enum';
import CreateDevelopmentStore from '@/domains/development-store/views/create-development-store/CreateDevelopmentStore.vue';
import { RootPermissionsEnum } from '@/router/permissions/root-permissions.enum';
import store from '@/store';
import { PARTNER_STORES_PERMISSIONS } from '@/router/permissions/partitioned-permissions';

const { useGetters } = createNamespacedHelpers('authentication');

export default defineComponent({
  props: {
    stepsSuccess: {
      type: Object,
      required: true,
      default: {
        confirmEmail: false,
        partnershipContract: false,
        developmentStore: false,
        createApp: false,
      },
    },
    accountCompleted: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    BaseText,
    CreateDevelopmentStore,
    ZidIcon,
    ZidButton,
    ZidModal,
    ZidInput,
  },
  emits: ['showAppTypeSelectionModal'],
  setup(props) {
    const { email, isAccountOwner } = useGetters(['email', 'isAccountOwner']);
    const grantedPermissionsList: RootPermissionsEnum[] = [...(store.getters['teamMember/grantedPermissions'] || [])];

    const partnerEmail = email.value;
    const creatDevStoreBtnShown = ref(false);
    const creatAppBtnShown = ref(false);
    const showSendEmailConfirmationModal = ref(false);
    const toggleDevStoreBtn = () => (creatDevStoreBtnShown.value = !creatDevStoreBtnShown.value);
    const togglcreateAppBtn = () => (creatAppBtnShown.value = !creatAppBtnShown.value);
    const toggleSendEmailConfirmationModal = () =>
      (showSendEmailConfirmationModal.value = !showSendEmailConfirmationModal.value);

    const isTeamMemberWithPermission = computed(
      () =>
        !isAccountOwner.value &&
        PARTNER_STORES_PERMISSIONS.every((permission) => grantedPermissionsList.includes(permission)),
    );

    const showCreateDevelopmentStore = computed(() => {
      return (
        (creatDevStoreBtnShown && isAccountOwner.value) || (creatDevStoreBtnShown && isTeamMemberWithPermission.value)
      );
    });

    const onResendEmailClicked = async () => {
      const response = await reSendPartnerConfirmationEmail({
        email: partnerEmail,
      });
      if (response.status === 'success') {
        Vue.$toast.success(`Confirmation E-mail has been sent`);
      } else {
        Vue.$toast.error(`Failed to send E-mail, please try again later`);
      }
      toggleSendEmailConfirmationModal();
    };

    const onGoToPartnershipPageClicked = () => {
      if (!props.stepsSuccess.confirmEmail) {
        Vue.$toast.error(``, {
          message: 'Please confirm your E-mail before going to partnership form',
          position: 'top',
        });
        return;
      }
      router.push({ name: RoutingRouteEnum.partnershipDetails }).catch(() => {
        //
      });
    };

    const onGoToDevelopmentStoresClicked = () => {
      router.push({ name: RoutingRouteEnum.DevelopmentStore }).catch(() => {
        //
      });
    };

    return {
      creatDevStoreBtnShown,
      creatAppBtnShown,
      showSendEmailConfirmationModal,
      partnerEmail,
      showCreateDevelopmentStore,
      toggleDevStoreBtn,
      togglcreateAppBtn,
      toggleSendEmailConfirmationModal,
      onResendEmailClicked,
      onGoToPartnershipPageClicked,
      onGoToDevelopmentStoresClicked,
    };
  },
});
