import dayjs from 'dayjs';
import ErrorModel from '@/api/models/ErrorModel';
import { getAnalytincsOverviewData } from '../../../api/dashboard/shipping/analyticsOverview';
import { StatisticsControlStateType, AnalyticsOverviewReturnType } from '../../../types/shipping/dashboard.type';

const useAnalyticsOverview = async (
  statisticsControlState: StatisticsControlStateType,
): Promise<AnalyticsOverviewReturnType> => {
  let localTotalOrders = 0;
  let localTotalActivations = 0;
  let localCompletedOrders = 0;
  let localTotalZidFee = 0;
  const startDate = statisticsControlState.startDate;
  const endDate = statisticsControlState.endDate;

  const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
  const formattedendDate = dayjs(endDate).format('YYYY-MM-DD');
  const selectedApplicationId = statisticsControlState.selectedApp.value;
  const response = await getAnalytincsOverviewData(selectedApplicationId, formattedStartDate, formattedendDate);
  if (response instanceof ErrorModel || !response.payload) {
    return {
      localTotalOrders,
      localTotalActivations,
      localCompletedOrders,
      localTotalZidFee,
    };
  }
  localTotalOrders = Number(response.payload.total_orders) ?? 0;
  localTotalActivations = Number(response.payload.total_activations) ?? 0;
  localCompletedOrders = Number(response.payload.completed_orders) ?? 0;
  localTotalZidFee = Number(response.payload.total_zid_fee) ?? 0;

  return {
    localTotalOrders,
    localTotalActivations,
    localCompletedOrders,
    localTotalZidFee,
  };
};

export default useAnalyticsOverview;
